<template lang="pug">
div.wrap-module-record.pb30
  div.record-list
    div.wrap-client-info.f.fm.flex-between.mt20.py20.px20(v-if="client")
      div.wrap-profile.f.fm.flex-wrap
        div.f.fm.s-mb12
          div.wrap-img.f.fm.mr12
            img(:src="client.photoURL")
          div.s-show
            div.f.fm.mb4
              span.name.mr4 {{ client.name }}
              v-icon.mr6(@click="onEdit" size="20px") edit
        div
          div.s-hide.f.fm.mb4
            span.name.mr4 {{ client.name }}
            v-icon.mr6(@click="onEdit" size="20px") edit
          div
            div.f
              span.latest-log.mr12(v-if="latestGoal") {{ `最新の目標：${latestGoal}` }}
              span.latest-log(v-if="latestWeight") {{ `最新の体重：${latestWeight}kg` }}
            div.f
              span.latest-log.mr12(
                v-if="latestMaxPressure && latestMaxPressure !== 0"
              ) {{ `最高血圧：${latestMaxPressure}` }}
              span.latest-log(
                v-if="latestMinPressure && latestMinPressure !== 0"
              ) {{ `最低血圧：${latestMinPressure}` }}
      div
        v-menu(offset-y)
          template(v-slot:activator="{ on }")
            div.add-record-button.f.fm.px6.py4(v-on="on")
              v-icon add
              span.pt2.pr4 記録を追加
          v-list.px12.pt14.pb4.mt4
            div.f.fm.mb12(
              v-for="(item, index) in menus",
              :key="index",
              @click="onList(item)")
              v-icon.mr3(size="16px", :color="item.color") {{ item.icon }}
              span {{ item.title }}
        //- div.start-chat-button.f.fm.px6.py5.mt8(
          v-if="client && client.authedClientUid",
          @click="onStartChat")
          v-icon.pl2.pr4(color="#fff", size="18px") chat
          span チャット

    div.wrap-records-header.f.fm.flex-between.px20.pb4
      div.wrap-selector.f.fm.pt4
        v-icon(size="20px") arrow_drop_down
        select.pt2(v-model="filterLabel")
          option(v-for="item in filterItems") {{ item }}
      // div.pt4.f.fm
        v-icon(
          v-for="item in tabIcons"
          :size="item.size"
          @click="onRecordHeaderIcon(item.tabName)").mr4 {{item.icon}}

    div(v-if="currentTab === 'list' && cards && cards[0]")
      ItemRecordCard(v-for="record in cards", :content="record")

    div(v-if="currentTab === 'calendar' && cards && cards[0]")
      // span calendar

    div.wrap-no-records.f.fh(v-if="cards && !cards[0]")
      div
        span.mb12 トレーニングメニューの記録を追加しましょう。
</template>

<style lang="scss" scoped>
@import "@/scss/_variables.scss";

.wrap-module-record {
  width: 93%;
  max-width: 820px;
  margin: 0 auto;
  padding-top: 48px;
  .record-list {
    background: $primary_bg_color;
    border-radius: 3px;
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
      0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    .wrap-client-info {
      border-bottom: $secondary_border;
      .wrap-profile {
        .wrap-img {
          width: 72px;
          height: 72px;
          overflow: hidden;
          border: $secondary_border;
          border-radius: 50%;
          img {
            object-fit: cover;
            min-width: 100%;
            min-height: 100%;
          }
        }
        .name {
          display: block;
          font-size: 18px;
        }
        .copy-invitation-link {
          cursor: pointer;
          color: $active_color;
        }
        .latest-log {
          display: block;
          color: $primary_text_color;
          font-size: 12px;
        }
      }
    }

    .wrap-records-header {
      border-bottom: $secondary_border;
      height: 30px;
      select {
        outline: none;
      }
    }

    .add-record-button,
    .start-chat-button {
      cursor: pointer;
      background: $active_color;
      border-radius: 3px;
      span {
        font-size: 14px;
        font-weight: bold;
        color: #fff;
      }
    }
    .wrap-no-records {
      height: calc(100vh - 184px);
      span {
        display: block;
        text-align: center;
      }
    }
  }
  .v-btn {
    margin: 0;
  }

  @media only screen and (max-width: 600px) {
    .wrap-client-info {
      display: block;
      .wrap-profile {
        margin-bottom: 12px;
      }
      .add-record-button,
      .start-chat-button {
        display: inline-flex;
        font-size: 14px;
        span {
          padding-bottom: 1px;
        }
      }
      .start-chat-button {
        position: relative;
        top: -3px;
        margin-left: 8px;
      }
    }
  }
}
</style>

<script>
import db from '@/components/utils/firebase'
import { createNamespacedHelpers } from 'vuex'
import ItemRecordCard from '@/components/item/ItemRecordCard'
const { mapState: mapStateAuth } = createNamespacedHelpers('auth')
const { mapActions: mapActionsClient } = createNamespacedHelpers('client')
const { mapState: mapStateRecord, mapActions: mapActionsRecord } = createNamespacedHelpers('record')

export default {
  components: {
    ItemRecordCard
  },
  props: {
  },
  data () {
    return {
      client: null,
      cards: [],
      menus: [
        { title: 'カルテ', icon: 'description', color: 'indigo' },
        { title: 'コンサルテーション', icon: 'hearing', color: 'green' },
        { title: '体組成計', icon: 'accessibility', color: 'orange' }
      ],
      filterLabel: '全て',
      filterItems: [
        '全て', 'カルテ', 'コンサルテーション', '体組成計'
      ],
      tabIcons: [
        { tabName: 'list', icon: 'sort' },
        { tabName: 'calendar', icon: 'calendar_today', size: '19px' }
      ],
      currentTab: 'list',
      latestWeight: null,
      latestGoal: null,
      latestMaxPressure: null,
      latestMinPressure: null
    }
  },
  computed: {
    ...mapStateAuth(['uid']),
    ...mapStateRecord(['records']),
    invitationURL: function () {
      // return `${location.origin}/client-sign-up/${this.$route.params.clientId}`
      return `https://habitfivepercent.com/client-sign-up/${this.$route.params.clientId}`
    }
  },
  watch: {
    records: function (records) {
      this.cards = records
      this.filterByType()
    },
    filterLabel: function (newItem) {
      this.filterLabel = newItem
      this.filterByType()
    }
  },
  async created () {
    this.init()
  },
  methods: {
    ...mapActionsClient(['getClient']),
    ...mapActionsRecord([
      'loadRecords',
      'getPreviousRecordOf',
      'getLatestRecordOf',
      'createRecord'
    ]),
    async init () {
      this.client = await this.getClient(this.$route.params.clientId)
      await this.loadRecords(this.$route.params.clientId)

      this.cards = this.records.filter(e => { return true })

      var latestKarteRecord = await this.getLatestRecordOf({
        created: new Date(),
        clientId: this.$route.params.clientId,
        type: 'karte'
      })

      var latestConsultationRecord = await this.getLatestRecordOf({
        created: new Date(),
        clientId: this.$route.params.clientId,
        type: 'consultation'
      })

      if (latestKarteRecord) {
        this.latestWeight = latestKarteRecord.clientWeight
        this.latestMaxPressure = latestKarteRecord.maxPressure
        this.latestMinPressure = latestKarteRecord.minPressure
      }
      if (latestConsultationRecord) this.latestGoal = latestConsultationRecord.goalThisMonth
    },
    async onList (list) {
      switch (list.title) {
        case 'カルテ':
          var previousRecord = await this.getPreviousRecordOf({
            created: new Date(),
            clientId: this.$route.params.clientId,
            type: 'karte'
          })
          // if (previousRecord) {
          //   var clientWeight = previousRecord.clientWeight
          // } else {
          //   var clientWeight = 40
          // }

          var clientWeight = (previousRecord && previousRecord.clientWeight) ? previousRecord.clientWeight : 40
          var maxPressure = (previousRecord && previousRecord.maxPressure) ? previousRecord.maxPressure : 0
          var minPressure = (previousRecord && previousRecord.minPressure) ? previousRecord.minPressure : 0

          var recordObj = {
            label: 'パーソナルトレーニング',
            memo: '',
            clientWeight: clientWeight,
            maxPressure: maxPressure,
            minPressure: minPressure,
            body: [],
            uid: this.uid,
            clientId: this.$route.params.clientId,
            type: 'karte',
            trainingDate: new Date(),
            created: new Date(),
            updated: new Date()
          }

          var id = await this.createRecord(recordObj)
          this.$router.push(`/record/karte/${this.$route.params.clientId}/${id}`)
          break

        case 'コンサルテーション':
          var consultationObj = {
            label: 'コンサルテーション',
            goalThisMonth: '',
            goalLastMonth: '',
            mealMorning: '',
            mealLunch: '',
            mealDinner: '',
            mealOther: '',
            sleep: '',
            defecation: '',
            workout: '',
            other: '',
            uid: this.uid,
            clientId: this.$route.params.clientId,
            type: 'consultation',
            created: new Date(),
            updated: new Date()
          }

          id = await this.createRecord(consultationObj)
          this.$router.push(`/record/consultation/${this.$route.params.clientId}/${id}`)
          break

        case '体組成計':
          var inbodyObj = {
            label: '体組成計',
            uid: this.uid,
            imgs: [],
            measuredAt: new Date(),
            clientId: this.$route.params.clientId,
            type: 'inbody',
            created: new Date(),
            updated: new Date()
          }

          id = await this.createRecord(inbodyObj)
          this.$router.push(`/record/inbody/${this.$route.params.clientId}/${id}`)
          break
      }
      // mixpanel.track('onList', {
      //   createdAt: new Date(),
      //   type: list.title
      // })
    },
    async onStartChat () {
      var groupDoc = await db.collection('GROUPS')
        .doc(`${this.uid}-${this.$route.params.clientId}`)
        .get()
        .then(d => { return d })
      if (!groupDoc.exists) {
        await db.collection('GROUPS')
          .doc(`${this.uid}-${this.$route.params.clientId}`)
          .set({
            authedClientUid: this.client.authedClientUid,
            clientId: this.$route.params.clientId,
            member: [this.uid, this.$route.params.clientId],
            createdBy: this.uid,
            created: new Date(),
            updated: new Date()
          })
      }
      this.$router.push(`/inbox/${this.uid}-${this.$route.params.clientId}`)
      // mixpanel.track('onStartChat', {
      //   createdAt: new Date(),
      //   type: this.filterLabel
      // })
    },
    filterByType () {
      switch (this.filterLabel) {
        case '全て':
          this.cards = this.records.filter(e => { return true })
          break
        case 'カルテ':
          this.cards = this.records.filter(e => { return e.type === 'karte' })
          break
        case 'コンサルテーション':
          this.cards = this.records.filter(e => { return e.type === 'consultation' })
          break
        case '体組成計':
          this.cards = this.records.filter(e => { return e.type === 'inbody' })
          break
      }
      // mixpanel.track('filterByType', {
      //   createdAt: new Date(),
      //   type: this.filterLabel
      // })
    },
    // onCreateRecord () {
    //   this.$router.push(`/record/karte/${this.$route.params.clientId}/new`)
    // },
    onEdit () {
      this.$emit('openModalWindow')
      // mixpanel.track('onEdit', { createdAt: new Date() })
    },
    onCopy () {
      alert('招待リンクをコピーしました。このリンクをお客様にシェアしましょう。')
      // mixpanel.track('onCopy', { createdAt: new Date() })
    },
    onRecordHeaderIcon (tabName) {
      this.currentTab = tabName
    },
    async updateClientInfo () {
      this.client = await this.getClient(this.$route.params.clientId)
    }
  }
}
</script>
