<template lang="pug">
  Auth(@loginFailed="onFailedAuthentication")
    Header
    ModalWindow(
      @closeModal="closeModalWindow"
      modalContentId="createClient"
      :showModal="showModalWindow")
    ModalPage(
      v-if="uid"
      :showModal="showModalPage"
      :headerRightIcon="headerRightIcon"
      :modalPageContentId="modalPageContentId"
      :autoSaveTrigger="autoSaveTrigger"
      @headerOnLeft="headerOnLeft"
      @closeModalPage="closeModalPage")
    div.wrap-record
      ModuleRecord(
        ref="moduleRecord"
        v-if="uid"
        @openModalPage="openModalPage"
        @openModalWindow="openModalWindow")
</template>

<style lang="scss" scoped>
.wrap-record {
  position: relative;
  width: 100%;
  min-height: 100vh;
}
</style>

<script>
import { createNamespacedHelpers } from 'vuex'
import Auth from '@/components/auth'
import Header from '@/components/shared/Header.vue'
import HeaderModal from '@/components/shared/HeaderModal.vue'
import ModalWindow from '@/components/shared/Modal.vue'
import ModalPage from '@/components/shared/ModalPage.vue'
import ModuleRecord from '@/components/module/ModuleRecord.vue'
const { mapState: mapStateAuth, mapActions: mapActionsAuth } = createNamespacedHelpers('auth')

export default {
  components: {
    Auth,
    Header,
    HeaderModal,
    ModalWindow,
    ModalPage,
    ModuleRecord
  },
  data() {
    return {
      showModalWindow: false,
      showModalPage: false,
      headerRightIcon: {
        icon: 'done',
        label: '保存済'
      },
      modalPageContentId: 'setRecord',
      autoSaveTrigger: false
    }
  },
  computed: {
    ...mapStateAuth(['uid'])
  },
  watch: {
    $route: function (to, from) {
      if (to.path !== from.path) {
        if (this.$route.params.recordId) {
          var recordType = this.$route.params.recordType

          switch (recordType) {
            case 'karte':
              this.modalPageContentId = 'setRecord'
              break
            case 'consultation':
              this.modalPageContentId = 'setConsultation'
              break
            case 'inbody':
              this.modalPageContentId = 'setInbody'
              break
          }

          this.showModalPage = true
        } else {
          this.showModalPage = false
        }
      }
    }
  },
  created() {
    if (this.$route.params.recordId) this.showModalPage = true

    var recordType = this.$route.params.recordType
    switch (recordType) {
      case 'karte':
        this.modalPageContentId = 'setRecord'
        break
      case 'consultation':
        this.modalPageContentId = 'setConsultation'
        break
      case 'inbody':
        this.modalPageContentId = 'setInbody'
        break
    }
  },
  methods: {
    ...mapActionsAuth(['signOut']),
    onFailedAuthentication() {
      this.$router.push('/sign-in')
    },
    openModalPage() {
      this.showModalPage = true
    },
    closeModalPage() {
      this.showModalPage = false
    },
    headerOnLeft() {
      this.$refs.moduleRecord.init()
      this.$router.push(`/record/${this.$route.params.clientId}`)
    },
    openModalWindow() {
      this.showModalWindow = true
    },
    closeModalWindow() {
      this.showModalWindow = false
    },
    updateClientInfo() {
      this.$refs.moduleRecord.updateClientInfo()
    }
  },
  beforeRouteLeave(to, from, next) {
    this.autoSaveTrigger = !this.autoSaveTrigger
    this.$nextTick(() => {
      next()
    })
  }
}
</script>
